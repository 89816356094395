<template>
  <div class="auditDetails">
    <div class="txt-area">
      <div class="txt-area-item txt-area-head">
        <span>审核情况</span>
        <span @click="againApply" v-if="applyInfo.auditStatus != 2">重新认证</span>
      </div>
      <div class="txt-area-item">
        <span>提交审核时间</span>
        <span>{{$way.timestampReturnDate(applyInfo.summitTime, 'timeYMDHM') || '暂无'}}</span>
      </div>
      <div class="txt-area-item">
        <span>审核状态</span>
        <span :class="{audits: true, audits_ing: applyInfo.auditStatus == 1, audits_pass: applyInfo.auditStatus == 2, audits_fail: applyInfo.auditStatus == 3}">{{$common.auditStatus[applyInfo.auditStatus]}}</span>
      </div>
      <div class="txt-area-item">
        <span>审核意见</span>
        <span>{{applyInfo.auditOpinion || '暂无'}}</span>
      </div>
      <div class="txt-area-item">
        <span>审核时间</span>
        <span>{{$way.timestampReturnDate(applyInfo.auditTime, 'timeYMDHM') || '暂无'}}</span>
      </div>
    </div>
    <div class="img-area">
      <div class="txt-area-item txt-area-head">
        <span>证件照片</span>
      </div>
      <div class="img-area-cont">
        <div class="img-area-cont-item" v-for="(item, index) in applyInfo.photoUrls" :key="index">
          <!-- <span>露脸手持证件照</span> -->
          <van-image
            fit="contain"
            :src="item"
            @click="previewEvent([item])"
          />
        </div>
      </div>
    </div>

    <van-action-sheet
      v-model="show"
      :actions="actions"
      cancel-text="取消"
      close-on-click-action
      @select="onSelect"
    />
  </div>
</template>

<script>
import { ImagePreview } from 'vant'
export default {
  name: 'auditDetails',
  data () {
    return {
      applyInfo: {},
      actions: [{ name: '重新人脸认证', value: 'face' }],
      // actions: [{ name: '重新人脸认证', value: 'face' }, { name: '重新申请人工审核', value: 'audit' }],
      show: false
    }
  },
  watch: {
    '$store.state.isGetToken' (val) {
      if (val && this.$route.name == 'auditDetails') {
        this.auditDetailEvent()
      }
    }
  },
  created () {
    this.auditDetailEvent()
  },
  methods: {
    auditDetailEvent () {
      this.$request.auditDetail().then(res => {
        if (res.data.code == 0) {
          res.data.data.photoUrls = res.data.data.photoUrls.split(';')
          this.applyInfo = res.data.data
        }
      })
    },
    // 图片预览
    previewEvent (images) {
      ImagePreview({
        images, // 图片集合
        closeable: true	// 关闭按钮
      })
    },
    againApply () {
      this.show = true
    },
    onSelect (item) {
      const type = this.$store.state.deviceType
      if (item.value == 'face') {
        if (type == 1) {
          window.webkit.messageHandlers.getData.postMessage('gotoFace()')
        } else if (type == 2) {
          try {
            window.Flutter.postMessage('gotoFace')
          } catch (error) {
            console.log(error, 'flutter')
          }
          try {
            window.Android.gotoFace()
          } catch (error) {
            console.log(error, '原生')
          }
        }
      } else {
        this.$router.push({ name: 'applyReview' })
      }
    }
  }
}
</script>

<style lang='scss'>
  .auditDetails {
    height: 100vh;
    padding: 16px 16px 0;
    overflow: scroll;
    background-color: #F9F9F9;
    .txt-area, .img-area {
      padding: 0 16px;
      background-color: #fff;
      border-radius: 4px;
      overflow: hidden;
      .txt-area-item {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        padding: 16px 0;
        border-bottom: 1px solid #F9F9F9;
        &>span:nth-of-type(2) {
          color: #363840;
        }
        .audits {
          display: inline-block;
          box-shadow: 0px 2px 4px 0px rgba(91,91,91,0.16);
          border-radius: 4px;
          font-size: 14px;
          font-weight: 400;
          padding: 2px 6px;
        }
        .audits_ing {
          color: #E2751A!important;
          border: 1px solid #E2751A;
        }
        .audits_pass {
          color: #1CB74D!important;
          border: 1px solid #1CB74D;
        }
        .audits_fail {
          color: #E21A1A!important;
          border: 1px solid #E21A1A;
        }
      }
      .txt-area-item:last-of-type {
        border: 0 none;
      }
      .txt-area-head {
        font-size: 18px;
        font-weight: 500;
        color: #363840;
        &>span:nth-of-type(2) {
          color: #1A7EE2;
        }
      }
    }
    .img-area {
      margin: 16px 0 20px;
      .img-area-cont {
        .img-area-cont-item {
          overflow: hidden;
          padding: 16px 0;
          border-bottom: 1px solid #F9F9F9;
          .van-image {
            width: 264px;
            height: 151px;
          }
        }
      }
    }
  }
</style>
